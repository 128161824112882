// <-- Bootstrap overrides
$grid-breakpoints: (
  xxxs: 0,
  xxs: 360px,
  xs: 576px,
  sm: 768px,
  md: 1024px,
  lg: 1280px,
  xl: 1440px,
  xxl: 1920px,
);

$grid-columns: 24;
$grid-gutter-width: 6px;

// -->
$gutter-width: 2px;
$column-width: 4.166666667%;

$white: #ffffff;
$green: #3a7d5c;
$lightGreen: #55b987;
$lightRed: #d75750;
$lightBlue: #0090bc;
$amber:#fd7d01;

$borderBlack: #191a1f;
$mildBlack: #0a1520; // new black. every day.

$successColor: $lightGreen;
$failureColor: $lightRed;

/*
  * SPACING
*/

$base-space: 16px !default;

$space-xxs: calc(0.125 * #{$base-space}); // 2
$space-xs: calc(0.25 * #{$base-space}); // 4
$space-xs2: calc(0.375 * #{$base-space}); // 6
$space-sm: calc(0.5 * #{$base-space}); // 8
$space-sm2: calc(0.625 * #{$base-space}); // 10
$space-md: calc(0.75 * #{$base-space}); // 12
$space-md2: calc(0.875 * #{$base-space}); // 14
$space-md3: calc(0.9375 * #{$base-space}); // 15
$space-lg: calc(1 * #{$base-space}); // 16
$space-xl: calc(1.125 * #{$base-space}); // 18
$space-xxl: calc(1.25 * #{$base-space}); // 20
$space-xxxl: calc(1.875 * #{$base-space}); // 30

/*
  * FONT SIZES
*/

$font-sm0: 0.5rem !default; // 8px
$font-sm1: 0.625rem !default; // 10px
$font-sm1-5: 0.6875rem !default; // 11px
$font-sm2: 0.75rem !default; // 12px
$font-sm2-fix: 0.7562rem !default; // 12.1px Temporary fix - as digits have issue with current font on 12px.
$font-sm3: 0.8125rem !default; // 13 px
$font-sm4: 0.875rem !default; // 14 px
$font-sm5: 0.9375rem !default; // 15 px

$font-md1: 1rem !default; // 16 px
$font-md2: 1.0625rem !default; // 17px
$font-md3: 1.125rem !default; // 18px
$font-md4: 1.1875rem !default; // 19px
$font-md5: 1.25rem !default; // 20px


$font-lg1: 1.375rem !default; // 22px
$font-lg2: 1.5rem !default; // 24px
$font-lg3: 1.625rem !default; // 26px
$font-lg4: 1.75rem !default; // 28px

$font-xl1: 1.875rem !default; // 30px
$font-xl2: 2rem !default; // 32px
$font-xl3: 2.125rem !default; // 34px
$font-xl4: 2.25rem !default; // 36px
$font-xl5: 2.375rem !default; // 38px

/*
  * BORDER RADIUS
*/
$radius-0: 0 !default;
$radius-1: 2px !default;
$radius-2: 4px !default;
$radius-3: 6px !default;
$radius-4: 8px !default;
$radius-5: 10px !default;
$radius-6: 12px !default;
$radius-7: 14px !default;
$radius-8: 16px !default;
$radius-9: 18px !default;
$radius-10: 20px !default;

/*
  STORE ALL z-index values here. So we can easily identify how can we stack the elements.
*/
$z-index-mapping: (
  'homepage-sticky-tabs': 4,
  'homepage-tables': 3,
);

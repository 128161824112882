@import 'modules/variables';

.font-light {
  font-family: var(--fontLight) !important;
}

.font-bold {
  font-family: var(--fontBold) !important;
}

.font-regular {
  font-family: var(--fontRegular) !important;
}

.font-semi-bold {
  font-family: var(--fontSemiBold) !important;
}

.fullWidth {
  width: 100% !important;
}

.deIcon {
  color: inherit;
  font-style: normal;
  text-rendering: optimizelegibility;
  text-align: center;
  text-transform: none;
  display: inline-block;
  line-height: 1;
  vertical-align: 0.125em;
  -webkit-font-smoothing: antialiased;

  & + span {
    margin-left: 5px;
  }

  &:not(.link) {
    & + span {
      margin-left: 10.75px;
    }
  }

  svg {
    display: inline-block;
  }
}

.deltaBtn {
  box-shadow: none;
  white-space: nowrap;
  text-align: center;
  position: relative;
  display: inline-block;
  border: 0.8px solid transparent;
  user-select: none;
  vertical-align: middle;
  cursor: pointer;

  &.loading {
    opacity: 0.8;
    cursor: wait;
  }

  &:active {
    &:not(:disabled) {
      opacity: 0.7;
      outline: 0;
      cursor: default;
    }
  }
}

@mixin deltaBtnStyle(
  $primaryColor,
  $hoverColor,
  $disabledColor,
  $textColor: var(--main-text-primary),
  $hoverBorderColor: false
) {
  border-color: $primaryColor;
  background-color: $primaryColor;
  border-radius: 2px;
  color: $textColor;
  width: auto;

  &:hover,
  &:active {
    &:not(:disabled) {
      background-color: $hoverColor;

      @if $hoverBorderColor {
        border-color: $hoverBorderColor;
      }
    }
  }

  &:disabled {
    background-color: $disabledColor;
    border-color: $disabledColor;
    cursor: not-allowed;
  }
}

@mixin deltaBtnActiveStyle($variant, $bgColor, $color: var(--main-text-primary)) {
  &.active {
    pointer-events: none;
    cursor: default;

    background-color: $bgColor;
    color: $color;
  }
}

@mixin deltaBtnFocusedStyle($bordercolor: var(--brand-text-primary)) {
  // * INFO: using "focus-visible" to enable focus style only on keyboard navigation
  &:focus-visible {
    border: 1px solid var(--primaryTheme);
  }
}

%primary {
  @include deltaBtnStyle(
    var(--brand-bg-primary),
    var(--brand-bg-hover),
    var(--brand-bg-disabled),
    var(--main-text-on-bg)
  );
}

%specialPrimary {
  @include deltaBtnStyle(var(--positive), var(--positive-bg-hover), var(--positive-bg-disabled));
}

.primary-t1 {
  padding: 16px;
  line-height: 18px;
  font-family: var(--fontSemiBold) !important;
  font-size: $font-md1;
  height: 56px;
  @extend %primary;
}

.primary-t2 {
  padding: 12px;
  line-height: 18px;
  font-family: var(--fontSemiBold) !important;
  font-size: $font-sm4;
  height: 42px;
  @extend %primary;
}

.primary-t3 {
  padding: 10px;
  line-height: 20px;
  font-family: var(--fontSemiBold) !important;
  font-size: $font-sm4;
  height: 40px;
  @extend %primary;
}

.primary-t4 {
  padding: 8px;
  line-height: 20px;
  font-family: var(--fontSemiBold) !important;
  font-size: $font-sm4;
  height: 36px;
  @extend %primary;
}

.primary-t5 {
  padding: 5px;
  line-height: 16.8px;
  font-family: var(--fontSemiBold) !important;
  font-size: $font-sm4;
  height: 20px;
  @extend %primary;
}

.primary-t6 {
  font-family: var(--fontRegular) !important;
  height: 32px;
  line-height: 22px;
  padding: 5px;
  font-size: $font-sm2;
  @include deltaBtnStyle(var(--negative), var(--negative-bg-hover), var(--negative-bg-disabled));
}

.special-primary-t1 {
  @extend %specialPrimary;
  height: 50px;
  padding: 6px 8px 8px 8px;
  font-family: var(--fontBold) !important;
  font-size: $font-sm4;
}

.special-primary-t2 {
  @extend %specialPrimary;
  height: 42px;
  padding: 6px 8px 8px 8px;
  font-family: var(--fontBold) !important;
  font-size: $font-sm4;
  line-height: 16.8px;
}

%secondaryAction {
  &:hover,
  &:active {
    &:not(:disabled) {
      border: 1px solid var(--primaryTheme);
      background-color: var(--brand-bg-primary);
      color: var(--primaryButtonText);
    }
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.secondary-t1 {
  background-color: var(--main-bg-primary);
  border: 1px solid var(--brand-border-primary);
  border-radius: 2px;
  color: var(--brand-text-primary);
  font-family: var(--fontRegular) !important;
  font-size: $font-sm4;
  width: auto;
  padding: 9px 20px;
  line-height: 16.8px;
  height: 36px;

  @extend %secondaryAction;
}

.secondary-t2 {
  background-color: var(--brand-bg-muted);
  border: 1px solid var(--primaryTheme);
  border-radius: 2px;
  color: var(--brand-text-primary);
  font-family: var(--fontRegular) !important;
  font-size: $font-sm4;
  width: auto;
  padding: 9px 20px;
  line-height: 16.8px;
  height: 36px;

  @extend %secondaryAction;
}

.secondary-t3 {
  background-color: var(--buttonSecondaryT3);
  border: 1px solid var(--main-text-secondary);
  border-radius: 2px;
  color: var(--main-text-secondary);
  font-family: var(--fontSemiBold) !important;
  font-size: $font-sm4;
  width: auto;
  padding: 10px 20px;
  line-height: 20px;
  height: 40px;

  @extend %secondaryAction;
}

.secondary-t4 {
  background-color: var(--brand-bg-muted);
  border: 1px solid var(--primaryTheme);
  border-radius: 2px;
  color: var(--brand-text-primary);
  font-family: var(--fontRegular) !important;
  font-size: $font-sm2;
  width: auto;
  padding: 6px 18px;
  line-height: 7px;
  height: 20px;

  @extend %secondaryAction;
}

.special-secondary-t1 {
  height: 32px;
  color: var(--brand-text-primary);
  font-size: $font-sm4;
  cursor: pointer;
  background-color: var(--divider-primary);
  border: 0.8px solid var(--divider-primary);
  border-radius: 2px;
  font-family: var(--fontRegular) !important;
  line-height: 22px;
  padding: 5px 22px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:hover {
    &:not(:disabled) {
      background-color: var(--divider-primary);
      color: var(--brand-text-primary);
    }
  }
}

.special-secondary-t2 {
  height: 32px;
  color: var(--negative-text-on-muted);
  font-size: $font-sm2;
  cursor: pointer;
  // background-color: var(--negative-bg-muted);
  border: 0.8px solid var(--negative-border-primary);
  border-radius: 2px;
  font-family: var(--fontLight) !important;
  line-height: 22px;
  padding: 5px 10px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:hover {
    &:not(:disabled) {
      background-color: var(--negative-bg-primary);
      color: var(--main-text-on-bg);
    }
  }
}

.special-secondary-t3 {
  height: 32px;
  color: var(--main-text-primary);
  font-size: $font-sm4;
  cursor: pointer;
  background-color: var(--highlight);
  border: 0.8px solid var(--highlight);
  border-radius: 2px;
  font-family: var(--fontRegular) !important;
  line-height: 16.8px;
  padding: 7px 20px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:hover {
    &:not(:disabled) {
      background-color: var(--warning-bg-hover);
      color: var(--main-text-primary);
    }
  }
}

.special-secondary-t4 {
  height: 44px;
  color: var(--main-text-secondary);
  font-size: $font-sm2;
  cursor: pointer;
  background-color: var(--main-bg-primary);
  border: 1px solid var(--main-bg-primary);
  border-radius: 2px;
  font-family: var(--fontRegular) !important;
  line-height: 10px;
  padding: 5px 22px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:hover {
    &:not(:disabled) {
      background-color: var(--divider-primary);
      border-color: var(--brand-text-primary);
      color: var(--main-text-primary);
    }
  }
}

.special-secondary-t5 {
  height: 44px;
  color: var(--brand-text-primary);
  font-size: $font-sm2;
  cursor: pointer;
  background-color: var(--main-bg-primary);
  border: 1px solid var(--primaryTheme);
  border-radius: 2px;
  font-family: var(--fontRegular) !important;
  line-height: 10px;
  padding: 5px 22px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}

.btnLink {
  color: var(--brand-text-primary);
  border-color: transparent;
  background: 0 0;
}

.underlineHover {
  &:hover {
    border-bottom: 1px solid var(--brand-border-primary);
  }
}

.link-t1 {
  font-size: $font-sm2;
  line-height: 15px;

  &:disabled {
    color: var(--brand-bg-disabled);
    cursor: not-allowed;
  }

  &:hover {
    &:not(:disabled) {
      color: var(--brand-bg-hover);

      svg {
        opacity: 0.8;
      }
    }
  }
}

.link-t2 {
  font-size: $font-sm4;

  &:disabled {
    color: var(--brand-bg-disabled);
    cursor: not-allowed;
  }
}

.link-t3 {
  font-size: $font-sm4;
  font-family: var(--fontSemiBold) !important;

  &:disabled {
    color: var(--brand-bg-disabled);
    cursor: not-allowed;
  }
}

.wait {
  opacity: 0.8;
  pointer-events: none;
}

/* #region Toggle Button */
.toggle-t5 {
  height: 24px;
  padding: 0 12px;
  font-size: $font-sm2;
  font-family: var(--fontRegular) !important;

  @include deltaBtnStyle(
    transparent,
    var(--main-bg-primary-alt),
    transparent,
    var(--main-text-secondary)
  );

  @include deltaBtnFocusedStyle();

  @include deltaBtnActiveStyle('toggle-t5', var(--main-bg-primary-alt));
}
/* #endregion */

.toggle-t6 {
  width: 57px;
  height: 28px;
  padding: 0 12px;
  font-size: $font-sm2-fix;
  font-family: var(--fontRegular) !important;

  @include deltaBtnStyle(
    transparent,
    var(--divider-primary2),
    transparent,
    var(--main-text-secondary)
  );

  @include deltaBtnFocusedStyle();

  @include deltaBtnActiveStyle('toggle-t6', var(--brand-border-primary), var(--main-text-on-bg));
}

.badge-t1 {
  border: 1px solid transparent;
  color: var(--main-text-secondary);
  padding: 0 12px;
  font-size: $font-sm2-fix;
  font-family: var(--fontRegular) !important;

  @include deltaBtnFocusedStyle();

  &.active {
    pointer-events: none;
    cursor: default;

    border-color: var(--brand-border-primary);
    color: var(--main-text-primary);
  }
}
/* #region Action Buttons */
.action-t4 {
  height: 24px;
  padding: 0 12px;
  font-size: $font-sm2;
  font-family: var(--fontRegular) !important;

  @include deltaBtnStyle(
    var(--divider-primary),
    var(--divider-primary),
    transparent,
    var(--main-text-tertiary),
    var(--brand-bg-primary)
  );

  @include deltaBtnFocusedStyle();
}

.lineHeightUnset {
  line-height: unset;
}

.transparent {
  background-color: transparent;
}
/* #endregion */

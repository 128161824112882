@import 'screen-sizes';

.wrapper {
  box-shadow: none;
  border: none;
  background-color: transparent;
  transform: rotate(0);
  cursor: pointer;
  &.transition {
    transition: transform 0.6s ease;
  }
  &:active {
    // Show touch effect on mobile/tablets only
    @media screen and (max-width: $md) {
      transform: scale(0.9);
      transition: 0.1s;
    }
  }
  &.rotate270 {
    transform: rotate(270deg);
  }
  &.rotate180 {
    transform: rotate(180deg);
  }
  &.rotate90 {
    transform: rotate(90deg);
  }
  &.rotate45 {
    transform: rotate(45deg);
  }
  &.flipY {
    transform: scaleY(-1);
  }
  &.flipX {
    transform: scaleX(-1);
  }
  &.disabled {
    cursor: not-allowed;
  }
}

.iconColorDark {
  svg {
    path {
      fill: rgba(20, 20, 20, 0.687);
    }
  }
}

// use when font-weight 700
@font-face {
  font-family: 'Aileron Bold';
  font-style: normal;
  font-display: fallback;
  src: url('../fonts/Aileron-Bold.woff2') format('woff2');
}

// use when font-weight 600
@font-face {
  font-family: 'Aileron SemiBold';
  font-style: normal;
  font-display: fallback;
  src: url('../fonts/Aileron-SemiBold.woff2') format('woff2');
}

// use when font-weight 400
@font-face {
  font-family: 'Aileron Regular';
  font-style: normal;
  font-display: fallback;
  src: url('../fonts/Aileron-Regular.woff2') format('woff2');
}

// use when font-weight 300
@font-face {
  font-family: 'Aileron Light';
  font-style: normal;
  font-display: fallback;
  src: url('../fonts/Aileron-Light.woff2') format('woff2');
}

@import 'styles/screen-sizes.scss';

.infoBox {
  width: 100%;
  height: 150px;
  border-radius: 10px;
  background-color: var(--main-bg-primary-alt);
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  gap: 29.27px;
  padding: 0px 24.29px;
}

.imageSection {
  img {
    height: 80px;
    @media (min-width: $sm) and (max-width: $md) {
      width: 40px;
      height: auto;
    }
  }
}

.header {
  font-size: 16px;
  line-height: 20px;
  font-family: var(--fontSemiBold);
  color: var(--main-text-primary);
  margin-bottom: 5px;
}

.description {
  font-size: 12.1px;
  line-height: 20px;
  font-family: var(--fontRegular);
  color: var(--main-text-secondary);
}
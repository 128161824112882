@import '../screen-sizes';
@import 'modules/variables';

.bracket-order-info {
  background-color: var(--primaryBackground);

  .input-container {
    border: 1px solid var(--inputBorderColor);
    border-radius: var(--inputBorderRadius);
    &:focus-within {
      border: 1px solid var(--primaryTheme);
      border-radius: 2px;
    }
  }

  .inputType {
    color: var(--primaryText);
    background-color: var(--inputBackground);
    height: 36px;
    border-radius: 2px;

    @media screen and (max-width: 1023px) {
      height: 44px;
    }
  }
  .bracket-show-exisiting-values {
    background-color: var(--primaryBackground);
    border-bottom: 1px solid var(--separator);
    display: flex;
    margin: 0px;
    height: 40px;
    .entry-price {
      padding-left: 21px;
      padding-top: 6px;
    }
    .col-10 {
      padding-top: 6px;
    }
    .col-12 {
      padding-top: 6px;
    }
    .entry-price-value {
      font-size: 0.75625rem; // 12.1 px
      line-height: 1.29;
      color: var(--primaryText);
      margin-left: 10px;
      margin-right: 40px;
    }
    .label {
      font-size: 0.75625rem; // 12.1 px
      line-height: 1.29;
      color: var(--secondaryText);
    }
    .value {
      padding-left: 10px;
      width: 46px;
      font-family: var(--fontRegular);
      font-size: $font-sm2;
      line-height: 1.29;
      text-align: right;
      color: var(--primaryText);
    }
  }
  .bracket-header-row {
    background-color: var(--secondaryBackground);
    height: 41px;
    &.mobile-row {
      height: 38px;
      margin-top: 0px;
    }
    .bracket-order-header-title {
      font-size: $font-sm4;
      line-height: 18px;
      color: var(--primaryText);
      &.mobile {
        padding-right: 6%;
      }
    }

    .bracket-order-header-cancel {
      font-size: 0.75625rem; // 12.1 px
      line-height: 15px;
      color: var(--brand-text-primary);
      cursor: pointer;
      position: absolute;
      right: 12px;
      &.mobile {
        text-align: right;
      }
    }
    &.desktop-header {
      background-color: var(--separator);
      height: 38px;
    }
  }

  .bracket-order-input-labels {
    border-bottom: 0.2px dashed var(--secondaryText);
    padding-bottom: 1px;
    color: var(--secondaryText);
    font-size: 0.75625rem; // 12.1 px
    line-height: 15px;
  }

  .errorBorder {
    border: 1px solid var(--negative) !important;
    border-radius: 2px;
  }

  .bracket-desktop-header {
    background-color: var(--primaryText);
    height: 38px;
    display: flex;
    justify-items: center;
    .bracket-order-header-label {
      height: 18px;
      border: 1px solid var(--negative);
      padding-top: 7px;
      font-family: var(--fontRegular);
      font-size: $font-sm4;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: 0.6px;
      color: var(--secondaryText);
      span {
        text-align: center;
      }
    }
    .bracket-order-header-cancel-text {
      height: 18px;
      font-family: var(--fontRegular);
      font-size: $font-sm2;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.6px;
      padding-top: 7px;
      color: var(--brand-text-primary);
      cursor: pointer;
    }
  }
  .take-profit-text {
    // padding: 0px 12px;
    color: var(--primaryText);
    span {
      height: 18px;
      font-size: $font-sm2;
      font-family: var(--fontRegular);
      line-height: 1.5;
      color: var(--primaryText);
    }
  }

  .bracket-price-text {
    width: 98%;
    font-size: 0.6875rem; // 11px
    color: var(--secondaryText);
    padding-bottom: 5px;
  }

  .optional-text {
    font-size: 0.5625rem; // 9px
  }
  .quote-symobl {
    font-size: $font-sm1;
  }
  .bracket-row-1 {
    margin: 16px 0 0 0;
    // padding: 0 12px;
  }
  .bracket-icon-text {
    background-color: var(--inputBackground);
    margin-left: -3px;
    padding-right: 6px;
    padding-left: 5px;
    padding-bottom: 1px;
    display: flex;
    align-items: center;
    border-radius: 2px;
    svg {
      cursor: pointer;
    }
    .bracket-icon:hover path {
      fill: var(--primaryTheme);
    }
    .bracket-icon {
      &.active path {
        fill: var(--primaryTheme);
      }
    }
  }
  .bracket-row-3 {
    margin: 16px 0 0 0;
    // padding: 0 12px !important;
  }

  .bracket-row-last {
    margin: 28px 0 0 0 !important;
  }
  .set-bracket-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--fontRegular);
    font-size: $font-sm4;
    line-height: 18px;
    width: 100%;
    &:hover {
      background-color: var(--primaryButtonHover);
    }
    height: 40px;
    border-radius: 2px;
    background-color: var(--brand-bg-primary);
    cursor: pointer;
    &.active {
      pointer-events: auto;
    }
    &.disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  .bracket-error-notification-message {
    border: none;
    margin-top: 5px;
    // margin-left: 12px;
    // margin-right: 12px;
    .text {
      font-size: $font-sm1;
      line-height: 13px;
      color: var(--negative) !important;

      @media screen and (max-width: 1023px) {
        font-size: 0.75625rem; // 12.1px
        line-height: 15px;
      }
    }
  }
  .bracket-notification-message {
    border: none;
    margin-top: 5px;
    // margin-left: 12px;
    // margin-right: 12px;
    .text {
      font-size: $font-sm1;
      line-height: 13px;
      color: var(--brand-text-primary) !important;
      @media screen and (max-width: 1023px) {
        font-size: 0.75625rem; // 12.1px
        line-height: 15px;
      }
    }
    .roe-message {
      text-align: left;
    }
  }

  .is-active-bracket-order {
    color: var(--secondaryText) !important;
  }
  .bracket-row-4
    > div:nth-child(1)
    > div
    > span
    > span:nth-child(2)
    > span
    > span {
    padding: 0px !important;
    margin-left: 4px;
  }
  .bracket-row-4
    > div.col-10.trail-stop
    > div
    > span
    > span:nth-child(2)
    > span
    > span {
    padding: 0px !important;
    margin-left: 1px;
  }
  .bracket-row-4 {
    // padding: 0 12px !important;

    .black-text {
      margin-bottom: 5px;
      height: 18px;
      font-family: var(--fontRegular);
      font-size: $font-sm2;
      line-height: 1.5;
      color: var(--secondaryText);
    }
    .active {
      color: var(--primaryText) !important;
    }
    .stop-loss-text {
      padding-top: 0px;
      // height: 18px;
      font-size: $font-sm2;
      font-family: var(--fontRegular);
      line-height: 1.5;
      max-width: 120px;
      word-break: break-word;
      @media screen and (max-width: $md) {
        max-width: max-content;
      }
    }
    // .trail-stop-label {
    //   padding-left: 18px;
    // }
    .trail-stop {
      margin-left: 0px;
      padding-left: 16px;
    }
    .toggle-bracket {
      // padding-left: 29px;
      .react-toggle {
        margin-top: 0px;
        margin-left: -5px;
        margin-right: -5px;
        width: 36px;
      }
      input[type='checkbox'] + label::before {
        content: '';
        display: block;
        position: absolute;
        border-radius: 256px;
        width: calc(38px - 2 * 2px);
        height: calc(7px - 2 * 2px);
        background-color: var(--secondaryText);
        left: 4px;
        right: 15px;
      }
      input[type='checkbox']:checked + label::before {
        background-color: var(--secondaryText);
      }
      input[type='checkbox'] + label {
        cursor: pointer;
      }
      input[type='checkbox'] + label {
        display: inline-grid;
        box-sizing: border-box;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        outline: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: 24px;
        max-width: 24px !important;
        height: 18px;
        background-color: var(--primaryBackground);
        border-radius: 256px;
        cursor: pointer;
        -webkit-transition: background ease-out 0.3s;
        transition: background ease-out 0.3s;
      }
      input[type='checkbox']:checked + label:after {
        background-color: var(--brand-bg-primary);
      }
      input[type='checkbox']:checked + label {
        background-color: var(--secondaryBackground);
      }
      input[type='checkbox'] + label:after {
        background-color: var(--brand-bg-primary);
      }
      input[type='checkbox']:checked + label:after {
        margin-left: calc(100% - 10px - 4px);
        -webkit-transition: all ease-out 0.2s;
        transition: all ease-out 0.2s;
      }
    }
  }
  &.open-position-box {
    background-color: var(--primaryBackground);
    width: 495px;

    .set-bracket-header-row {
      height: 56px;
      padding-top: 16px;
      background-color: var(--primaryBackground);
      border-bottom: 1px solid var(--separator);
      display: flex;
      justify-content: center;
      .title {
        height: 22px;
        font-family: var(--fontRegular);
        font-size: $font-md1;
        line-height: 1.38;
        text-align: center;
        color: var(--primaryText);
      }
    }
    .take-profit-text {
      // margin: 15px;
      background-color: var(--primaryBackground) !important;
      font-size: $font-sm4;
      height: 18px;
      // padding-left: 10px;
      span {
        font-size: $font-sm2;
        font-family: var(--fontRegular);
        line-height: 1.5;
        color: var(--primaryText);
      }
      &.is-active-bracket-order {
        span {
          color: var(--secondaryText) !important;
        }
      }
    }
    // .bracket-row-1 {
    //   margin: 15px;
    //   margin-right: -10px;
    // }
    // .bracket-row-3 {
    //   margin: 15px;
    // }
    .bracket-error-notification-message {
      // margin-left: 25px;
      // margin-right: 15px;
      .text {
        font-size: 0.6875rem; // 11px
      }
    }
    .bracket-notification-message {
      // margin-left: 25px;
      // margin-right: 15px;
      .text {
        font-size: 0.6875rem; // 11px
      }
      .roe-message {
        text-align: left;
      }
    }
    .set-bracket-footer {
      padding-top: 1rem;
      padding-bottom: 1rem;
      display: flex;
      column-gap: 0.75rem;
      // padding: 15px;
      // padding-left: 25px;
      .set-bracket-button {
        height: 45px;
        flex: 1;
        button {
          width: 100%;
        }
        // padding-right: 0px;
        // padding-left: 4px;
        .button-text {
          &:hover {
            width: 95.76%;
            background-color: var(--primaryButtonHover);
          }
          width: 95.76%;
          background-color: var(--brand-bg-primary);
          height: 45px;
          font-family: var(--fontRegular);
          font-size: $font-sm4;
          line-height: 1.43;
          text-align: center;
          color: var(--primaryCtaText);
          border: 0px solid var(--primaryTheme);
          cursor: pointer;
          padding-bottom: 2px;
          &.active {
            pointer-events: auto;
          }
          &.disabled {
            background-color: var(--primaryButtonDisable);
            pointer-events: none;
          }
        }
      }
      .set-bracket-cancel {
        height: 45px;
        text-align: center;
        flex: 1;
        button {
          width: 100%;
        }
        .button-text {
          height: 45px;
          font-family: var(--fontRegular);
          font-size: $font-sm4;
          line-height: 1.43;
          text-align: center;
          color: var(--secondaryText);
          cursor: pointer;
          border: 1px solid var(--secondaryText);
          margin-right: 2%;
          padding-bottom: 2px;
        }
      }
    }
    .bracket-row-4 {
      // margin: 15px;
      background-color: var(--primaryBackground) !important;
      // margin-left: 14px;
      height: 24px;
      margin-bottom: 10px;
      .stop-loss-text {
        // width: 64px;
        width: auto;
        height: 18px;
        font-size: $font-sm4;
        line-height: 1.29;
        color: var(--secondaryText) !important;
      }
      .active {
        color: var(--primaryText) !important;
      }
      .trail-stop {
        padding-left: 24px !important;
      }
      .toggle-bracket {
        // padding-left: 16px;
        input[type='checkbox']:checked + label {
          background-color: var(--primaryBackground);
        }
        margin-left: 5px;
      }
      // .trail-stop-label {
      //   padding-left: 16px;
      // }
    }
    .bracket-show-exisiting-values {
      .col-12 {
        padding-left: 17px;
      }
      .entry-price {
        padding-left: 23px;
      }
    }
  }
  &.mobile-view-modal {
    min-width: 100%;
    width: 100%;
    height: 500px;
    background-color: var(--primaryBackground);
    overflow: auto !important;
    .bracket-show-exisiting-values {
      background-color: var(--primaryBackground);
      display: flex;
      margin: 0px;
      margin-left: -3px;
      padding-left: -6px;
      margin-right: -3px;
      height: 40px;
      .entry-price {
        padding-left: 15px;
        padding-top: 6px;
      }
      .col-12 {
        padding-top: 6px;
      }
      .entry-price-value {
        font-size: 0.75625rem; // 12.1px
        margin-left: 10px;
        margin-right: auto;
      }
      .label {
        font-size: 0.75625rem; // 12.1px
      }
      .value {
        font-size: 0.75625rem; // 12.1px
      }
    }
    .set-bracket-header-row {
      height: 56px;
      padding-top: 16px;
      background-color: var(--primaryBackground);
      display: flex;
      justify-content: center;
      .title {
        height: 22px;
        font-family: var(--fontRegular);
        font-size: $font-md1;
        line-height: 1.38;
        text-align: center;
        color: var(--primaryText);
      }
    }
    .bracket-order-header-title {
      &.mobile {
        padding-right: 0%;
      }
    }
    .take-profit-text {
      padding-left: 7px;
      padding-bottom: 12px;
      margin: 0px -3px;
      background-color: var(--primaryBackground) !important;
      padding-right: 0px;
      padding-top: 10px;
      height: 40px;
      span {
        font-size: $font-sm4;
        height: 18px;
        line-height: 1.5;
        color: var(-primaryText);
      }
      &.is-active-bracket-order {
        span {
          color: var(--secondaryText) !important;
        }
      }
    }
    .bracket-row-1 {
      margin: 0px;
    }
    .bracket-row-4 {
      height: 40px;
      background-color: var(--primaryBackground) !important;
      .stop-loss-text {
        width: auto;
        height: 18px;
        font-size: $font-sm4;
        line-height: 1.29;
        color: var(--secondaryText) !important;
      }
      .active {
        color: var(--primaryText) !important;
      }
      .toggle-bracket {
        input[type='checkbox']:checked + label {
          background-color: var(--primaryBackground);
        }
      }
    }
    // .bracket-row-3 {
    //   margin: 0px;
    // }
    .bracket-error-notification-message {
      margin-left: 7px;
      margin-right: 15px;
      .text {
        font-size: 0.6875rem; // 11px
      }
    }
    .bracket-notification-message {
      margin-left: 7px;
      margin-right: 15px;
      .text {
        font-size: 0.6875rem; // 11px
      }
      .roe-message {
        text-align: left;
      }
    }
    .set-bracket-footer {
      // padding: 15px;
      // padding-right: 2px;
      // padding-left: 7px;
      .set-bracket-button {
        height: 45px;
        padding-left: 5px;
        padding-right: 5px;
        .button-text {
          width: 98%;
          background-color: var(--brand-bg-primary);
          height: 45px;
          font-family: var(--fontRegular);
          font-size: $font-sm4;
          line-height: 1.43;
          text-align: center;
          color: var(--primaryCtaText);
          border: 0px solid var(--primaryTheme);
          cursor: pointer;
          &.active {
            pointer-events: auto;
          }
          &.disabled {
            pointer-events: none;
          }
        }
      }
      .set-bracket-cancel {
        height: 45px;
        text-align: center;
        padding-left: 5px;
        padding-right: 12px;
        .button-text {
          width: 96%;
          height: 45px;
          font-family: var(--fontRegular);
          font-size: $font-sm4;
          line-height: 1.43;
          text-align: center;
          color: var(--secondaryText);
          cursor: pointer;
          border: 1px solid var(--secondaryText);
          margin-right: 2%;
        }
      }
    }
    .bracket-price-text {
      font-size: $font-sm4 !important;
    }
  }
}

.bracket-oder-section-details {
  .action-button {
    width: 30px;
    height: 20px;
    border-radius: 1px;
    display: inline-block;
    text-align: center;
    padding-top: 1px;
    background-color: var(--separator);
    span {
      width: 16px;
      height: 14px;
      font-family: var(--fontRegular);
      font-size: 0.71875rem; // 11.5px
      line-height: 1.22;
      letter-spacing: 0.4px;
      color: var(--secondaryText);
    }
    &.disable {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }
    &:hover {
      background-color: var(--secondaryCTAHover);
    }
    &.icon {
      margin-right: 7px;
      margin-left: 5px;
      margin-top: 4px;
      padding-bottom: 2px;
      cursor: pointer;
      svg {
        margin-left: -2px;
        margin-top: -2px;
      }
    }
    &.disable-add {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
      &:hover {
        cursor: not-allowed;
      }
    }
  }
  .take-profit-button {
    width: 30px;
    height: 20px;
    border-radius: 1px;
    display: inline-block;
    margin-right: 5px;
    text-align: center;
    padding-top: 1px;
    background-color: var(--separator);
    span {
      width: 16px;
      height: 14px;
      font-family: var(--fontRegular);
      font-size: 0.71875rem; // 11.5px
      line-height: 1.22;
      letter-spacing: 0.4px;
      color: var(--secondaryText);
    }
    &.disable {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }
    &:hover {
      background-color: var(--secondaryCTAHover);
    }
  }
}

.tooltip-message-bracket-holdings {
  width: 148px;
  height: 58px;
  border-radius: 1px;
  background-color: var(--secondaryBackground);
  .first-row {
    height: 11px;
    font-family: var(--fontRegular);
    font-size: 0.5625rem; // 9px
    color: var(--secondaryText);
    margin-top: -5px;
  }
  .second-row {
    margin-top: 5px;
    height: 11px;
    font-family: var(--fontRegular);
    font-size: 0.5625rem; // 9px
    color: var(--secondaryText);
    .limit-price {
      margin-left: 0px;
      padding-left: 14px;
      width: 64px;
    }
    .trigger-price {
      width: 78px;
    }
    span {
      text-align: center;
    }
  }
  .third-row {
    margin-top: 5px;
    height: 13px;
    font-family: var(--fontRegular);
    font-size: $font-sm1;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    color: var(--secondaryText);
    .limit-price {
      margin-left: 0px;
      padding-left: 14px;
      width: 64px;
    }
    .trigger-price {
      width: 78px;
    }
  }
}

.take_price_triggerprice {
  // margin-left:-52%;
  .row-data-key-bracket {
    display: none;
  }
  .row-first {
    height: 40px;
    background-color: var(--primaryBackground);
    color: blue;
    .minus,
    .plus {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: var(--fontRegular);
    }
    .plus {
      border-right: 0.8px solid rgba(113, 119, 130, 0.2);
    }

    .selected-icon path {
      fill: var(--primaryButtonHover);
    }

    .minus-icon,
    .plus-icon {
      cursor: pointer;

      &.is-diabled-icon {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  .icon-button {
    border: none;
    cursor: pointer;
  }

  .select-roe-row {
    background-color: var(--primaryBackground);
    height: 28px;
    color: var(--secondaryText);
    font-family: var(--fontRegular) !important;
    font-size: $font-sm1;
    line-height: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--secondaryText);
    border-top: 0.8px solid var(--separator);
    border-bottom: 0.8px solid var(--separator);
  }

  .row-3 {
    font-size: 0.75625rem; // 12.1px
    line-height: 15px;
    color: var(--primaryText);
    background-color: var(--primaryBackground);

    .first-row-percentage {
      border-bottom: 0.8px solid rgba(113, 119, 130, 0.2);
    }

    .col-12 {
      &.roe-btn {
        height: 35px;
        text-align: center;
        cursor: pointer;
        border: none;
        &.border-btn {
          border-right: 0.8px solid rgba(113, 119, 130, 0.2);
        }
      }
    }

    .active {
      color: var(--brand-text-primary);
    }
  }
  .roe-section {
    padding-left: 0px;
    padding-right: 0px;

    .btn:first-child {
      border-bottom: 1px solid var(--separator);
    }
    .btn:last-child {
      border-bottom: 1px solid var(--separator);
    }
  }
}
.bracket-popover-body {
  box-shadow: var(--dropdownBoxShadow);
}

// .bracket-popup-trigger-price{
//   .bracket-popover-body{
//    // width: 95px !important;
//   }
//   .take_price_triggerprice{
//     //margin-left: -48% !important;
//   }
// }

.bracket-order-holding-area-mobile-view {
  display: flex;
  padding: 16px 0px;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid var(--separator);

  &.new {
    border-bottom: 1px solid var(--separator);
  }

  .action-items {
    display: flex;
    justify-content: flex-start;
    margin-top: -4px;
  }

  .icon-place {
    margin-left: 11px;
    margin-right: 4px;

    svg {
      path {
        fill: var(--primaryTheme);
      }
    }

    &.disable-icon {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  .col-16 {
    margin: 0 auto;
    padding-right: 0px;
  }

  .label {
    font-family: var(--fontLight);
    font-size: $font-sm2;
    line-height: 8px;
    color: var(--main-text-secondary);
  }
}

.bracket-order-holding-area-mobile-view-values {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid var(--separator);
  border-radius: 2px;
  border-top: 0;

  .valuesWrapper {
    padding-right: 24px;
    padding-bottom: 15px;
  }

  .label {
    font-family: var(--fontRegular);
    font-size: 0.6875rem; // 11px
    line-height: 8px;
    font-weight: 300;
    color: var(--secondaryText);
    padding-bottom: 8px;
  }

  .values {
    font-family: var(--fontRegular);
    font-size: $font-sm2;
    line-height: 8px;
    font-weight: 400;
    color: var(--primaryText);
  }
}

.select-price-bracket-order {
  cursor: pointer;
}

.place-order-mobile-bracket-take-profit-trigger-price {
  .second-row-percentage {
    border-bottom: 0px solid var(--separator) !important;
  }
}

.bracket-section-icon-info {
  svg {
    width: 16px !important;
    height: 16px !important;
  }
}

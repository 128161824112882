@import 'styles/screen-sizes';
@import 'styles/modules/variables';
@import 'mixins';

.paperRoot {
  margin: 0;
  max-width: 100%;
  max-height: 100% !important;
  width: 100%;
  height: 100%;
  margin-top: auto;
  background-color: transparent !important;
  font-family: var(--fontRegular);
  box-shadow: none;
}

.marginTopInherit {
  margin-top: inherit;
}

.dialogContent {
  padding: 0 !important;
  margin: auto;
  display: flex;
  flex: none;
  font-family: var(--fontRegular) !important;
  @media screen and (max-width: $xs) {
    margin-top: auto;
    margin-bottom: 0;
  }
}

.darkOverlay {
  background-color: var(--shade-black);
  opacity: 0.45 !important;
}

.lightOverlay {
  background-color: var(--shade-black);
  opacity: 0.45 !important;
}

.closeButton {
  width: 24px;
  height: 24px;
  padding: 7px;
  border-radius: $radius-2;
  @include flex-center-align;
  background-color: var(--main-bg-primary);
  position: absolute;
  right: 20px;
  top: 20px;

  .crossIcon {
    width: 10px;
    height: 10px;
    color: var(--main-text-secondary);
  }

  &:hover {
    .crossIcon {
      color: var(--brand-text-primary);
    }
  }

  @media screen and (max-width: $md) {
    right: 15px;
    top: 14px;
  }
}

.header {
  color: var(--main-text-primary);
  padding: 22px 20px;
  font-size: $font-md1;
  font-family: var(--fontSemiBold);
  line-height: 18px;
  border-bottom: 1px solid var(--divider-primary);

  @media screen and (max-width: $md) {
    padding: 17px 15px;
    font-family: var(--fontRegular);
  }
}

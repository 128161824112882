@import "styles/screen-sizes.scss";
@import "styles/modules/variables";

@mixin responsive-margin {
  @media (max-width: $xxs) {
    margin-left: 15px;
    margin-right: 15px;
  }

  @media (min-width: $xxs) and (max-width: $xs) {
    margin-left: 15px;
    margin-right: 15px;
  }

  @media (min-width: $xs) and (max-width: $md) {
    margin-left: 80px;
    margin-right: 80px;
  }

  @media (min-width: $md) and (max-width: $lg) {
    margin-left: 100px;
    margin-right: 100px;
  }

  @media (min-width: $lg) and (max-width: $xxl) {
    margin-left: 180px;
    margin-right: 180px;
  }

  @media (min-width: $xl) and (max-width: $xxl) {
    margin-left: 240px;
    margin-right: 240px;
  }

  @media (min-width: $xxl) {
    margin-left: 300px;
    margin-right: 300px;
  }
}

.mainWrapper {
  display: flex;
  flex-direction: column;
  .loginContentWrapper {
    flex-grow: 1;
  }
}

.loginContentWrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .contentGrid {
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    margin: 0px;
    padding-top: 50px;
    padding-bottom: 50px;

    & > div {
      padding: 0px;
    }
  }
  .isTestNet {
    padding-top: 0;
  }

  @media (max-width: $xs) {
    .contentGrid {
      height: 100%;
      padding-bottom: 136px;
      & > div:first-child {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  @include responsive-margin;
}

.loginHeader {
  color: var(--main-text-primary);
  font-family: var(--fontRegular);
  line-height: 38.4px;
  font-size: 26px;
  margin-bottom: 25px;

  @media only screen and (max-width: $xs) {
    font-size: 18px;
    line-height: 21.6px;
  }
}

.formFieldParent {
  margin-bottom: 25px;
}

.oauthContainer {
  margin-bottom: 29px;
}

.submitBtn {
  margin-bottom: 20px;
}

.defaultErrorMessage {
  color: var(--negative-text-primary);
  font-family: var(--fontRegular);
  font-size: 12.1px;
  line-height: 14px;
  padding-left: 19px;
}

.infoSection {
  margin-top: 75px;
}

.errorMessage {
  color: var(--negative-text-primary);
  font-family: var(--fontRegular);
  font-size: 12.1px;
  line-height: 18px;
  padding-left: 19px;
  padding-top: 10px;

  @media only screen and (max-width: $xs) {
    padding-left: 15px;
  }
}

.oAuthErrorMessage {
  padding-top: 4px;
  font-size: $font-sm2;
  color: var(--negative);
  font-family: var(--fontRegular);
}

.link {
  color: var(--brand-text-primary) !important;
  text-decoration: none;
}

.hashLimitLoginErrMsg {
  font-size: $font-sm2;
  line-height: 11px;
  padding-top: $space-xs;
  font-family: var(--fontRegular);
  color: var(--negative-text-primary);
}

.hashLimitLoginErrMsgLink {
  color: var(--brand-text-primary);
  text-decoration: none;
}

.formContainer {
  @media only screen and (min-width: $md) {
    min-width: 450px;
  }
}

.loginLinksSection {
  display: flex;
  margin-top: 15px;
  margin-bottom: 25px;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 25px;
  @media only screen and (max-width: $xs) {
    flex-direction: column;
    gap: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

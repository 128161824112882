@import "styles/screen-sizes.scss";
@import "styles/modules/variables";
@import "mixins";

a.link {
  color: var(--brand-text-primary);

  &:hover {
    text-decoration: underline;
  }
}

.linksSection {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  a {
    font-size: $font-sm2;
    line-height: 11px;
  }
}

.downloadApp {
  font-size: $font-sm2;
  line-height: 11px;
  color: var(--main-text-secondary);
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 33px;
  svg {
    cursor: pointer;
  }
  @media only screen and (max-width: $xs) {
    justify-content: center;
    margin-bottom: 30px;
  }
}

.signupMessage {
  font-size: $font-sm2;
  line-height: 11px;
  color: var(--main-text-secondary)
}

.giftBoxImg {
  max-height: 58px;
}

.loginViaBiometricsRoot {
  margin-top: 6em;

  .loginViaBiometricsButton {
    height: unset;
  }
}

.iosOAuthTip {
  @include flex-start-both;
  text-align: center;
  background-color: var(--main-bg-primary);
  border-radius: 2px;
  padding: 12px 13px;
  margin-top: 20px;

  div {
    svg {
      vertical-align: inherit;
    }
  }

  div:nth-child(2) {
    font-family: var(--fontRegular);
    font-size: $font-sm2;
    line-height: 22px;
    color: var(--main-text-secondary);
    text-align: left;
    padding-left: 7px;
  }
}

@media screen and (max-width: $md) {
  .iosOAuthTip {
    padding: 13px 15px;

    div:nth-child(2) {
      line-height: 20px;
      padding-left: 9px;
    }
  }
}

.errorMessage {
  color: var(--negative);
  font-family: var(--fontRegular);
  font-size: 12.1px;
  line-height: 18px;
  padding-left: 19px;
  padding-top: 10px;

  @media only screen and (max-width: $xs) {
    padding-left: 15px;
  }
}

.oAuthErrorMessage {
  padding-top: 4px;
  font-size: $font-sm2;
  color: var(--negative);
  font-family: var(--fontRegular);
}

.link {
  color: var(--brand-text-primary) !important;
  text-decoration: none;
}

.complianceMessage {
  font-size: $font-sm2;
  font-weight: 400;
  line-height: 22px;
  color: var(--main-text-secondary);
}

.complianceMessageClickableContent {
  color: var(--brand-text-primary);
  text-decoration: none;
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid var(--brand-border-primary);
  }
}

.complianceContainer {
  background: var(--main-bg-surface-alt);
  padding: 10px 15px;
  border-radius: 2px;
  margin-top: 10px;
  display: flex;
  gap: 8px;
}

.deltaIndiaLinkMessage {
  @include flex-center-vert;
  flex-wrap: wrap;
  gap: 5px;
  font-size: $font-sm2;
  margin-top: 25px;
}

.deltaLogo {
  width: 16px;
  height: 16px;
}

.redirectIcon {
  width: 12px;
  height: 12px;
}

.deltaIndiaLinkContent {
  @include flex-center-vert;
  gap: 5px;
}

.orText {
  text-transform: uppercase;
  margin: 0px 5px;
}

.userNotFoundMessage {
  padding-top: 4px;
  font-size: $font-sm2;
  line-height: normal;
  color: var(--main-text-secondary);
}

.userNotFoundSuggestionMessage {
  @include flex-center-vert;
  flex-wrap: wrap;
  gap: 5px;
  padding-top: 4px;
}

.modalContent {
  display: block;
  position: relative;
  width: 414px;
  height: fit-content;
  background-color: var(--modalBackground);
  border-radius: $radius-2;
  border: 1px solid var(--divider-primary);

  @media only screen and (max-width: $xs) {
    width: 100%;
  }
}

.modalBody {
  padding: 37px 15px 24px 15px;
  font-size: $font-sm4;
  @include flex-column;
  @include flex-center-align;
  gap: 20px;
}

.iconContainer {
  width: 100px;
  height: 100px;
  @include flex-center-align;
  @include addColorToBackgroundWithOpacity(var(--negative), 0.1);

  &::after {
    border-radius: 50%;
  }

  svg {
    width: 45px;
    height: 45px;
    color: var(--negative);
  }
}

.signupButton {
  margin-top: 10px;
}

.clickHereButton {
  @include flex-center-align;
  height: 42px;
  background-color: transparent;
}

.deltaGlobalLoginLinkMessage {
  font-size: $font-sm2;
  line-height: 11px;
  gap: 10px;
  margin-top: 15px;
  @include flex-center-vert;
  margin-bottom: 30px;
  flex-wrap: wrap;
  @media only screen and (max-width: $xs) {
    @include flex-column;
    align-items: baseline;
    margin-bottom: 35px;
  }
}

.deltaGlobalLoginLinkText {
  gap: 5px;
  flex-wrap: wrap;
  @include flex-center-vert;
  @media screen and (max-width: "370px") {
    gap: 10px;
  }
}

.userPresentInGlobal {
  @include flex-center-vert;
  flex-wrap: wrap;
  gap: 5px;
  padding-top: 10px;
}

.downloadAppIcon {
  width: 32px;
  height: 32px;
  padding: 4px;
  border-radius: 4px;
  background-color: var(--main-bg-primary-alt);
  &:hover {
    background-color: var(--divider-primary2);
  }
}

.testNetWarning {
  font-size: 14px;
  line-height: 20px;
  border-radius: 10px;
  border: 1px solid var(--negative);
  color: var(--secondaryText);
  max-width: fit-content;
  padding: 15px 13px;
  flex-shrink: 1;
  letter-spacing: 0.3px;
  display: flex;
  gap: 8px;
  margin-top: 54px;
  margin-bottom: 62px;

  @media only screen and (max-width: $xs) {
    font-size: 12.1px;
    padding: 10px;
    margin-top: 26px;
    margin-bottom: 30px;
  }
}

.warningIcon {
  min-width: fit-content;
}

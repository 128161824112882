@import 'mixins';
@import 'modules/variables';
.light {
  font-family: var(--fontLight);
}

.bold {
  font-family: var(--fontBold);
}

.regular {
  font-family: var(--fontRegular);
}

.semi-bold {
  font-family: var(--fontSemiBold);
}

.negative {
  color: var(--negative-text-primary);
}

.positive {
  color: var(--positive-text-primary);
}

.primary {
  color: var(--main-text-primary);
}

.secondary {
  color: var(--main-text-secondary);
}

.secondaryAlt {
  color: var(--main-text-secondary);
}

.tertiary {
  color: var(--main-text-tertiary);
}

.tertiary-t2 {
  color: var(--main-text-secondary);
}

.warning {
  color: var(--warning);
}

.highlight {
  color: var(--highlight);
}

.primaryTheme {
  color: var(--brand-text-primary);
}

.primaryThemeIndian {
  color: var(--primaryThemeIndian);
}

.flex-column {
  @include flex-column;
}

.flex-row {
  @include flex-row;
}

.flex-center-align {
  @include flex-center-align;
}

.flex-row-spread {
  @include flex-row-spread;
}

.flex-col-spread {
  @include flex-col-spread;
}

.flex-center-column {
  @include flex-center-column;
}

.flex-center-vert {
  @include flex-center-vert;
}

.flex-start-both {
  @include flex-start-both;
}

.flex-end-both {
  @include flex-end-both;
}

.flex-align-right {
  @include flex-align-right;
}

.flex-align-left {
  @include flex-align-left;
}

.flex-justify-right {
  @include flex-justify-right;
}

.flex-justify-left {
  @include flex-justify-left;
}

.flex-row-spread-center {
  @include flex-row-spread-center;
}

.scrollYAuto {
  overflow-y: auto;
}

.scrollXAuto {
  overflow-y: auto;
}

.gap-2 {
  gap: 2px;
}

.gap-4 {
  gap: 4px;
}

.gap-6 {
  gap: 6px;
}

.gap-8 {
  gap: 8px;
}

.gap-10 {
  gap: 10px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.gap-20 {
  gap: 20px;
}

.noInset {
  inset: 0;
}

.text {
  font-size: $font-sm2;
  line-height: $font-sm2;
  white-space: nowrap;
  font-family: var(--fontRegular);
}

.heading {
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
  font-family: var(--fontRegular);
  margin: 0px;
}

.primaryBackground {
  background-color: var(--main-bg-surface-alt);
}

.secondaryBackground {
  background-color: var(--main-bg-primary-alt);
}

.tertiaryBackground {
  background-color: var(--main-bg-secondary);
}

.borderDark {
  border: 1px solid var(--main-bg-tertiary);
}

.ltr {
  direction: ltr;
}

.rtl {
  direction: rtl;
}

.unset {
  line-height: unset;
}

.initial {
  line-height: initial;
}

.inherit {
  line-height: inherit;
}

.normal {
  line-height: normal;
}

// =============================================================================
// Flex Alignment
// =============================================================================

@mixin flex-center($dir) {
  display: flex;
  flex-direction: #{$dir};
  justify-content: center;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-center-align {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-c-center {
  @include flex-column;

  align-items: center;
}

@mixin flex-j-center {
  @include flex-column;

  justify-content: center;
}

@mixin flex-r-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@mixin flex-center-vert {
  display: flex;
  align-items: center;
}

@mixin flex-center-column {
  @include flex-center-align;
  flex-direction: column;
}

@mixin flex-row-spread {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@mixin flex-row-spread-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-col-spread {
  @include flex-column;
  justify-content: space-between;
}

@mixin flex-align-right {
  display: flex;
  align-items: flex-end;
}

@mixin flex-align-left {
  display: flex;
  align-items: flex-start;
}

@mixin flex-justify-right {
  display: flex;
  justify-content: flex-end;
}

@mixin flex-justify-left {
  display: flex;
  justify-content: flex-start;
}

@mixin flex-start-both {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

@mixin flex-end-both {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

@mixin flex-start-center {
  @include flex-center-vert;
  justify-content: flex-start;
}

// =============================================================================
// SVG Style
// =============================================================================

@mixin svgStyle($color, $hoverColor, $height: 16px, $width: 16px) {
  svg {
    fill: $color !important;
    height: $height;
    width: $width;

    circle,
    line,
    path {
      fill: $color !important;
    }

    &:hover {
      fill: $hoverColor !important;

      circle,
      line,
      path {
        fill: $hoverColor !important;
      }
    }
  }
}

// =============================================================================
//  Scrollbar
// =============================================================================

@mixin hideScrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@mixin showScrollbar {
  &::-webkit-scrollbar {
    width: var(--scrollBarWidth);
    height: var(--scrollBarHeight);
  }
  scrollbar-width: var(--scrollBarWidthFirefox);
}

@mixin disableSelectHighlight {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

@mixin confirmButton {
  background-color: var(--brand-bg-primary);
  border: 1px solid var(--primaryTheme);
  color: var(--primaryCtaText);
  border-radius: 2px;
  cursor: pointer;

  &:hover:not([disabled]) {
    background-color: var(--primaryButtonHover);
    color: var(--primaryCtaTextHover);
  }

  &:disabled {
    background: var(--primaryButtonDisable);
    color: var(--primaryCtaText);
    cursor: not-allowed;
  }
}

@mixin cancelButton {
  background-color: var(--secondaryBackground);
  border: solid 1px var(--secondaryText);
  cursor: pointer;
}

// using it because we have hexcodes color in css variables and not rgb colors
@mixin addColorToBackgroundWithOpacity($bgColor, $opacity, $position: relative) {
  position: $position;
  &::after {
    content: '';
    background-color: $bgColor;
    opacity: $opacity;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@mixin addCustomListStyle($content: '\2022') {
  list-style: none;

  & li::before {
    content: $content;
    color: var(--brand-text-primary);
    font-size: 14px;
    font-family: var(--fontRegular);
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}

// =============================================================================
//  Font
// =============================================================================

@mixin monospace-font-family {
  font-family: monospace;
}
@import 'dark-theme';
@import 'light-theme';
@import 'indian-dark-theme';
@import 'indian-light-theme';

:root {
  --primaryTheme: #{$primaryTheme};
  --primaryThemeIndian: #{$primaryThemeIndian};
  --primaryThemeGlobal: #{$primaryTheme};
  --positive: #{$positive};
  --negative: #{$negative};
  --highlight: #{$highlight};
  --white: #{$white};
  --primaryBackground: #{$primaryBackground};
  --secondaryBackground: #{$secondaryBackground};
  --tertiaryBackground: #{$tertiaryBackground};
  --tagBackground: #{$tagBackground};
  --secondaryHover: #{$secondaryHover};
  --primaryText: #{$primaryText};
  --backgroundColor5: #{$backgroundColor5};
  --downloadAppIconBackground: #{$downloadAppIconBackground};
  // --labelBackground: #{$labelBackground};

  --secondaryText: #{$secondaryText};
  --primaryButtonText: #{$white};
  --tertiaryText: #{$tertiaryText};
  --textTertiary: #{$textTertiary};
  --textColor5: #{$textColor5};
  // --labelText: #{$labelText};

  --primaryButtonHover: #{$primaryButtonHover};
  --primaryButtonHoverIndian: #{$primaryButtonHoverIndian};
  --primaryButtonHoverGlobal: #{$primaryButtonHover};
  --primaryButtonDisable: #{$primaryButtonDisable};
  --positiveHover: #{$positiveHover};
  --negativeHover: #{$negativeHover};
  --negativeDisabled: #{$negativeDisabled};
  --positiveDisabled: #{$positiveDisabled};
  --secondaryCTA: #{$secondaryCTA};
  --secondaryCTAHover: #{$secondaryCTAHover};
  --highlightHover: #{$highlightHover};
  --separator: #{$separator};
  --separator2: #{$separator2};
  --separator3: #{$separator3};
  --highlightStrip: #{$highlightStrip};
  --highlightText2: #{$highlightText2};
  --negativeBlink: #{$negativeBlink};
  --warningBlink: #{$warningBlink};
  --positiveBlink: #{$positiveBlink};
  --warningColor: #{$warningColor};
  --borderColor: #{$borderColor};
  --tertiaryText: #{$tertiaryText};
  --stripBackground: #{$stripBackground};
  --stripBackground2: #{$stripBackground2};
  --signupButtonMobile: #{$signupButtonMobile};
  --negativeBorder: #{$negativeBorder};
  --warningBackground: #{$warningBackground};

  // font variables
  --fontLight: 'Aileron Light';
  --fontRegular: 'Aileron Regular';
  --fontSemiBold: 'Aileron SemiBold';
  --fontBold: 'Aileron Bold';

  // scrollBar variables
  --scrollBarWidth: 5px;
  --scrollBarHeight: 5px;
  --scrollBarWidthWide: 8px;
  --scrollBarHeightWide: 8px;
  --scrollBarThumbColor: #{$scrollBarThumbColor};
  --scrollBarTrackColor: transparent;
  --scrollBarBorderRadius: 5px;
  --scrollBarThumbColorHover: #{$scrollBarThumbColorHover};
  --scrollBarWidthFirefox: thin;

  // CTA
  --primaryCtaText: #ffffff;
  --primaryCtaTextHover: #ffffff;

  // Dropdown
  --dropdownBackground: #{$dropdownBackground};
  --dropdownBorderRadius: #{$dropdownBorderRadius};
  --dropdownBoxShadow: #{$dropdownBoxShadow};
  --dropdownItemHover: #{$dropdownItemHover};
  --dropdownIconColor: #{$dropdownIconColor};

  // Shadow
  --shadow: #{$shadow};

  // Table
  --tableRowHover: #{$tableRowHover};
  --tableSectionBackground: #{$tableSectionBackground};
  --tableLeverageBorder: #{$tableLeverageBorder};

  // Header
  --headerIconColor: #{$headerIconColor};

  --walletMenuBackground: #{$walletMenuBackground};
  --walletMenuBackgroundHover: #{$walletMenuBackgroundHover};
  --detoBackground: #{$detoBackground};

  // Auction Row
  --auctionRowBackground: #{$auctionRowBackground};
  --favoriteStarFill: #{$favoriteStarFill};

  // Input
  --inputBackground: #{$inputBackground};
  --inputAutofillPrimaryBg: #{$inputBackground};
  --inputAutofillSecondaryBg: #{$inputBackground};
  --inputBorderColor: #{$inputBorderColor};
  --inputBorderRadius: #{$inputBorderRadius};

  --unselectedTab: #{$unselectedTab};

  --trollBoxChatIconFill1: #{$trollBoxChatIconFill1};
  --trollBoxChatIconFill2: #{$trollBoxChatIconFill2};
  --trollBoxChatIconRing: #{$trollBoxChatIconRing};
  --basketEmptyIconFill1: #{$basketEmptyIconFill1};
  --basketEmptyIconFill2: #{$basketEmptyIconFill2};
  --basketEmptyIconFill3: #{$basketEmptyIconFill3};
  --basketInputRowBackground: #{$separator};
  --basketInputBackground: #{$separator2};
  --basketInputBorder: #{$separator2};
  --basketIconBackground: #{$separator2};

  //leaderboard colors
  --leaderBoardUserStrip: #{$leaderBoardUserStrip};
  --leaderBoardUserRankSecond: #{$leaderBoardUserRankSecond};
  --leaderBoardUserRankBronze: #{$leaderBoardUserRankBronze};

  --toggleSwitchOffBackground: #{$toggleSwitchOffBackground};

  // delta table fixed column shadows
  --fixedColumnShadowLeft: #{$fixedColumnDropShadowLeft};
  --fixedColumnShadowRight: #{$fixedColumnDropShadowRight};

  --circularProgressBackground: #{$circularProgressBackground};

  // tooltip
  --tooltipBorderBottom: #{$tooltipBorderBottom};
  --highlightBackground: #{$highlightBackground};

  --ioPutIndicator: rgba(0, 189, 132, 0.3);
  --ioCallIndicator: rgba(242, 78, 83, 0.3);
  --overlay: rgba(0, 0, 0, 0.5);

  --warning: #{$warningDark};
  --switch: #{$switchBg};

  //mobile-side-menu
  --sideMenuBorderBottom: #{$sideMenuBorderBottom};
  --quicklinkIcon: #{$quicklinkIcon};
  --buyCryptoIcon: #{$buyCryptoIcon};
  --buyCryptoStroke: #{$buyCryptoStroke};
  --investButton: #{$investButton};
  --mobileSideMenuArrowIconColor: #{$mobileSideMenuArrowIconColor};
  --mobileSideMenuSectionDividerColor: #{$mobileSideMenuSectionDividerColor};

  // range slider background
  --rangeSliderBackground: #{$rangeSliderBackground};
  --rangeSliderTooltipBackground: #{$rangeSliderBackground};

  --menuDropdownBackground: #{$menuDropdownBackground};
  --menuDropdownHover: #{$menuDropdownHover};
  --menuDropdownHeading: #{$menuDropdownHeading};
  --moreMenuIconFill: #{$moreMenuIconFill};
  --moreMenuIconStroke: #{$moreMenuIconStroke};

  --filterDropdownBackground: #{$secondaryBackground};

  // calendar styles
  --calendarBackground: #{$separator};
  --selectionHighlightBackground: #{$selectionHightlightBackground};
  --headerBackground: #{$separator2};

  --chartTooltipBg: #{$chartTooltipBg};
  --tickerLastPriceColor: #{$tickerLastPrice};
  --countrySearchInputBackground: #{$countrySearchInputBackground};

  // balances page
  --balancePageSeperator: #{$balancePageSeperator};
  // profile
  --profileVerifiedBackground: #{$profileVerifiedBackground};
  --profileUnverifiedBackground: #{$profileUnverifiedBackground};
  --profileFieldEditorBackground: #{$profileFieldEditorBackground};
  --profileFieldEditorSubmitBtn: #{$profileFieldEditorSubmitBtn};
  --profileFieldEditorCancelBtn: #{$profileFieldEditorCancelBtn};
  --buttonSecondaryT3: #{$secondaryBackground};
  --tabColorActive: #{$tabColorActive};
  --tabColorInActive: #{$tabColorInActive};
  --recommendedDepositStrip: #{$recommendedDepositStrip};
  --recommendedStripBackground: #{$recommendedStripBackground};
  --feesPopupIconColor: #{$feesPopupIconColor};
  --modalContentBackground: #{$modalContentBackground};
  --closeButtonBg: #{$closeButtonBg};
  --orderbookTooltipBackground: #{$orderbookTooltipBackground};
  --updatePasswordBorder: #{$separator};
  --customFeeBannerGreenColor: #{$customFeeBannerGreenColor};
  --customFeeBannerRedColor: #{$customFeeBannerRedColor};
  // options chain
  --otmShade: #{$otmShade};
  --strikeTableBoxShadowColor: #{$strikeTableBoxShadowColor};
  --optionsChainSeperator: #{$optionsChainSeperator};
  // New Modal
  --modalBackground: #{$chineseBlack};
  --modalBorder: #{$charlestonGreen};

  --unselectedTab2: #{$unselectedTab2};
  --tabContentBackground: #{$tabContentBackground};

  // options combos
  --countInfoBackground: #{$countInfoBackgroundColor};
  --slidesModalBackground: #{$slidesModalBackgroundColor};

  // benefit icons background
  --benefitBackground: #{$benefitBackgroundColor};

  //positions icon
  --positionIconBackground: #{$positionIconColor};

  //social links icon fill
  --socialLinkIconPathColor: #{$socialLinkIconPathColor};
  --socialLinkIconCircleColor: #{$socialLinkIconCircleColor};

  //new tooltip, to be followed to maintain consistency throught webapp
  --tooltipBottomDashedAndUnderlineColor: #{$tooltipBottomDashedAndUnderline};
  --tooltipInfoBoxColor: #{$tooltipInfoBox};
  --tooltipInfoBoxfontColor: #{$tooltipInfoBoxfont};

  --plusIconBgColor: #{$plusIconBgColor};
  --googleButtonBg: #{$primaryTheme};
  --googleButtonBgHover: #{$primaryButtonHover};

  //otp success modal
  --otpSuccessModalBackground: #{$otpSuccessModalBackgroundColor};
  --primaryTextLight: #{$primaryTextLight};

  // QR code
  --qrCodeBackground: #{$qrCodeBackground};
  --highlightBg2: #{$highlightBg2};
  --highlightText3: #{$highlightText3};

  --highlightBg: #{$highlightBg};
  --deltaLogoText: #{$deltaLogoText};
  --tagBg: #{$tagBg};
  --contractDetailMargin: #{$contractDetailMargin};
  --contractDetailPadding: #{$contractDetailPadding};

  // markets banner
  --marketsOverviewSwitchBtnBg: #{$marketsOverviewSwitchBtnBg};
  --marketBannerBtnBgColor: #{$marketBannerBtnBgColor};
  --marketBannerBgColor: #{$marketBannerBgColor};

  // Trade success share cards
  --tradeSuccessCardBg: #{$tradeSuccessCardBg};
  --tradeSuccessPopupBg: #{$tradeSuccessPopupBg};
  --tradeSuccessPopupHeaderBorder: #{$tradeSuccessPopupHeaderBorder};

  // Scalping Popup
  --scalpingPopupBg: #{$scalpingPopupBgDark};
  --scalpingPopupBorder: #{$scalpingPopupBorderDark};
  --scalpingPopupSectionBorder: #{$scalpingPopupSectionBorderDark};

  //calendar selected dates input
  --customDateBorderColor: #{$customDateBorderColor};

  --bankHeadingbgColor: #{$bankHeadingbgColor};

  --pillBg: #{$pillBg};
  --pillText: #{$pillText};
  --tShapeViewTabsBackground: #{$tShapeViewTabsBackground};

  // basket order
  --mainTextSecondary: #{$mainTextSecondary};
  --negativeBgPrimary: #{$negativeBgPrimary};
  --positiveBgPrimary: #{$positiveBgPrimary};
  --brandTextOnMuted: #{$brandTextOnMuted};
  --brandBgMuted: #{$brandBgMuted};
  --mainTextPrimary: #{$mainTextPrimary};
  --mainBgPrimaryAlt: #{$mainBgPrimaryAlt};
  --positiveBgMuted: #{$positiveBgMuted};
  --positiveTextOnMuted: #{$positiveTextOnMuted};
  --positiveTextPrimary: #{$positiveTextPrimary};
  --negativeBgMuted: #{$negativeBgMuted};
  --negativeTextPrimary: #{$negativeTextPrimary};
  --negativeTextOnMuted: #{$negativeTextOnMuted};
}

.light {
  --primaryTheme: #{$primaryThemeLight};
  --primaryThemeGlobal: #{$primaryThemeLight};
  --positive: #{$positiveLight};
  --negative: #{$negativeLight};
  --highlight: #{$highlightLight};
  --white: #{$white};
  --primaryBackground: #{$primaryBackgroundLight};
  --secondaryBackground: #{$secondaryBackgroundLight};
  --tertiaryBackground: #{$tertiaryBackgroundLight};
  --tagBackground: #{$tagBackgroundLight};
  --backgroundColor5: #{$backgroundColor5Light};
  --downloadAppIconBackground: #{$downloadAppIconBackgroundLight};
  // --labelBackground: #{$labelBackgroundLight};

  --secondaryHover: #{$secondaryHoverLight};
  --primaryText: #{$primaryTextLight};
  --secondaryText: #{$secondaryTextLight};
  --tertiaryText: #{$tertiaryTextLight};
  --textColor5: #{$textColor5Light};

  // --labelText: #{$labelTextLight};

  --primaryButtonText: #{$white};
  --textTertiary: #{$textTertiaryLight};
  --primaryButtonHover: #{$primaryButtonHoverLight};
  --primaryButtonHoverGlobal: #{$primaryButtonHoverLight};
  --primaryButtonDisable: #{$primaryButtonDisableLight};
  --positiveHover: #{$positiveHoverLight};
  --negativeHover: #{$negativeHoverLight};
  --negativeDisabled: #{$negativeDisabled};
  --positiveDisabled: #{$positiveDisabled};
  --secondaryCTA: #{$secondaryCTALight};
  --secondaryCTAHover: #{$secondaryCTAHoverLight};
  --separator: #{$separatorLight};
  --separator2: #{$separatorLight2};
  --separator3: #{$separatorLight3};
  --highlightStrip: #{$highlightStripLight};
  --highlightText2: #{$highlightTextLight2};
  --negativeBlink: #{$negativeBlinkLight};
  --warningBlink: #{$warningBlink};
  --positiveBlink: #{$positiveBlinkLight};
  --negativeBorder: #{$negativeBorderLight};
  --warningBackground: #{$warningBackgroundLight};

  // fontRegular is SemiBold in light theme as it looks better.
  --fontRegular: 'Aileron SemiBold';

  // Dropdown
  --dropdownBackground: #{$dropdownBackgroundLight};
  --dropdownBorderRadius: #{$dropdownBorderRadiusLight};
  --dropdownBoxShadow: #{$dropdownBoxShadowLight};
  --dropdownItemHover: #{$dropdownItemHoverLight};
  --dropdownIconColor: #{$dropdownIconColorLight};

  // Shadow
  --shadow: #{$shadowLight};

  // Table
  --tableRowHover: #{$tableRowHoverLight};
  --tableSectionBackground: #{$tableSectionBackgroundLight};
  --tableLeverageBorder: #{$tableLeverageBorderLight};

  // Header
  --headerIconColor: #{$headerIconColorLight};

  --walletMenuBackground: #{$walletMenuBackgroundLight};
  --walletMenuBackgroundHover: #{$walletMenuBackgroundHoverLight};
  --detoBackground: #{$detoBackgroundLight};

  // Auction Row
  --auctionRowBackground: #{$auctionRowBackgroundLight};
  --favoriteStarFill: #{$favoriteStarFillLight};

  // Input
  --inputBackground: #{$primaryBackgroundLight};
  --inputAutofillPrimaryBg: #{$primaryBackgroundLight};
  --inputAutofillSecondaryBg: #{$secondaryBackgroundLight};
  --inputBorderColor: #{$inputBorderColorLight};
  --inputBorderRadius: #{$inputBorderRadiusLight};

  --unselectedTab: #{$unselectedTabLight};

  --scrollBarThumbColor: #{$scrollBarThumbColorLight};
  --scrollBarThumbColorHover: #{$scrollBarThumbColorHoverLight};

  // Specific Icon Fills
  --trollBoxChatIconFill1: #{$trollBoxChatIconFill1Light};
  --trollBoxChatIconFill2: #{$trollBoxChatIconFill2Light};
  --trollBoxChatIconRing: #{$trollBoxChatIconRingLight};
  --basketEmptyIconFill1: #{$basketEmptyIconFill1Light};
  --basketEmptyIconFill2: #{$basketEmptyIconFill2Light};
  --basketEmptyIconFill3: #{$basketEmptyIconFill3Light};
  --basketInputRowBackground: #{$tagBackgroundLight};
  --basketInputBackground: #{$primaryBackgroundLight};
  --basketInputBorder: #{$separatorLight2};
  --basketIconBackground: #{$tagBackgroundLight};

  //extras
  //leaderboard colors
  --leaderBoardUserStrip: #{$leaderBoardUserStrip};
  --leaderBoardUserRankSecond: #{$leaderBoardUserRankSecond};
  --leaderBoardUserRankBronze: #{$leaderBoardUserRankBronze};
  --toggleSwitchOffBackground: #{$toggleSwitchOffBackgroundLight};

  // delta table fixed column shadows
  --fixedColumnShadowLeft: #{$fixedColumnDropShadowLeftLight};
  --fixedColumnShadowRight: #{$fixedColumnDropShadowRightLight};

  --circularProgressBackground: #{$circularProgressBackgroundLight};

  // tooltip
  --tooltipBorderBottom: #{$tooltipBorderBottomLight};
  --stripBackground: #{$stripBackgroundLight};
  --stripBackground2: #{$stripBackground2Light};
  --highlightBackground: #{$highlightBackgroundLight};

  --warning: #{$warningLight};
  --switch: #{$switchBgLt};
  //quicklink
  --quicklinkIcon: #{$quicklinkIconLight};
  --buyCryptoIcon: #{$buyCryptoIconLight};
  --buyCryptoStroke: #{$buyCryptoStrokeLight};
  --investButton: #{$investButtonLight};
  --sideMenuBorderBottom: #{$sideMenuBorderBottomLight};
  --mobileSideMenuArrowIconColor: #{$mobileSideMenuArrowIconColorLight};
  --mobileSideMenuSectionDividerColor: #{$mobileSideMenuSectionDividerColorLight};

  // range slider background
  --rangeSliderBackground: #{$rangeSliderBackgroundLight};
  --rangeSliderTooltipBackground: #{$secondaryBackgroundLight};

  --menuDropdownBackground: #{$menuDropdownBackgroundLight};
  --menuDropdownHover: #{$menuDropdownHoverLight};
  --menuDropdownHeading: #{$menuDropdownHeadingLight};
  --moreMenuIconFill: #{$moreMenuIconFillLight};
  --moreMenuIconStroke: #{$moreMenuIconStrokeLight};
  --countrySearchInputBackground: #{$countrySearchInputBackgroundLight};

  --filterDropdownBackground: #{$primaryBackgroundLight};

  // calendar styles
  --calendarBackground: #{$primaryBackgroundLight};
  --selectionHighlightBackground: #{$selectionHightlightBackgroundLight};
  --headerBackground: #{$tagBackgroundLight};

  --chartTooltipBg: #{$chartTooltipBgLight};
  --tickerLastPriceColor: #{$tickerLastPriceLight};

  // balances page
  --balancePageSeperator: #{$balancePageSeperatorLight};
  // profile
  --profileVerifiedBackground: #{$profileVerifiedBackgroundLight};
  --profileUnverifiedBackground: #{$profileUnverifiedBackgroundLight};
  --profileFieldEditorBackground: #{$profileFieldEditorBackgroundLight};
  --profileFieldEditorSubmitBtn: #{$profileFieldEditorSubmitBtnLight};
  --profileFieldEditorCancelBtn: #{$profileFieldEditorCancelBtnLight};
  --recommendedDepositStrip: #{$recommendedDepositStripLight};
  // button bg
  --buttonSecondaryT3: #{$buttonSecondaryT3Light};
  --tabColorActive: #{$tabColorActiveLight};
  --tabColorInActive: #{$tabColorInActiveLight};
  --recommendedStripBackground: #{$recommendedStripBackgroundLight};
  --feesPopupIconColor: #{$feesPopupIconColorLight};
  --modalContentBackground: #{$modalContentBackgroundLight};
  --closeButtonBg: #{$closeButtonBgLight};
  --orderbookTooltipBackground: #{$orderbookTooltipBackgroundLight};
  --updatePasswordBorder: #{$separatorLight2};
  --customFeeBannerGreenColor: #{$customFeeBannerGreenColorLight};
  --customFeeBannerRedColor: #{$customFeeBannerRedColorLight};
  // options chain
  --otmShade: #{$otmShadeLight};
  --strikeTableBoxShadowColor: #{$strikeTableBoxShadowColorLight};
  --optionsChainSeperator: #{$optionsChainSeperatorLight};

  // New Modal
  --modalBackground: #{$primaryBackgroundLight};
  --modalBorder: #{$separatorLight2};

  --unselectedTab2: #{$unselectedTab2Light};
  --tabContentBackground: #{$tabContentBackgroundLight};

  // options combos
  --countInfoBackground: #{$countInfoBackgroundColorLight};
  --slidesModalBackground: #{$slidesModalBackgroundColorLight};

  // benefit icon
  --benefitBackground: #{$benefitBackgroundColorLight};

  // position icon
  --positionIconBackground: #{$positionIconColorLight};

  //social links icon fill
  --socialLinkIconPathColor: #{$socialLinkIconPathColorLight};
  --socialLinkIconCircleColor: #{$socialLinkIconCircleColorLight};

  //new tooltip, to be followed to maintain consistency throught webapp
  --tooltipBottomDashedAndUnderlineColor: #{$tooltipBottomDashedAndUnderlineLight};
  --tooltipInfoBoxColor: #{$tooltipInfoBoxLight};
  --tooltipInfoBoxfontColor: #{$tooltipInfoBoxfontLight};

  --plusIconBgColor: #{$plusIconBgColorLight};
  --otpSuccessModalBackground: #{$otpSuccessModalBackgroundColorLight};
  --signupButtonMobile: #{$signupButtonMobileLight};

  // QR code
  --qrCodeBackground: #{$qrCodeBackgroundLight};
  --highlightBg2: #{$highlightBg2Light};
  --highlightText3: #{$highlightText3Light};

  // QR code
  --qrCodeBackground: #{$qrCodeBackgroundLight};

  --highlightBg: #{$highlightBgLight};
  --deltaLogoText: #{$deltaLogoTextLight};
  --tagBg: #{$tagBgLight};
  --contractDetailMargin: #{$contractDetailMargin};
  --contractDetailPadding: #{$contractDetailPadding};

  // markets banner
  --marketsOverviewSwitchBtnBg: #{$marketsOverviewSwitchBtnBgLight};
  --marketBannerBgColor: #{$marketBannerBgColorLight};
  --marketBannerBtnBgColor: #{$marketBannerBtnBgColorLight};

  // Trade success share cards
  --tradeSuccessCardBg: #{$tradeSuccessCardBgLight};
  --tradeSuccessPopupBg: #{$tradeSuccessPopupBglight};
  --tradeSuccessPopupHeaderBorder: #{$tradeSuccessPopupHeaderBorderLight};

  // Scalping Popup
  --scalpingPopupBg: #{$scalpingPopupBgLight};
  --scalpingPopupBorder: #{$scalpingPopupBorderLight};
  --scalpingPopupSectionBorder: #{$scalpingPopupSectionBorderLight};

  //calendar selected dates input
  --customDateBorderColor: #{$customDateBorderColorLight};

  --bankHeadingbgColor: #{$bankHeadingbgColorLight};

  --pillBg: #{$pillBgLight};
  --pillText: #{$pillTextLight};
  --tShapeViewTabsBackground: #{$tShapeViewTabsBackgroundLight};

  // basket order
  --mainTextSecondary: #{$mainTextSecondaryLight};
  --negativeBgPrimary: #{$neativeBgPrimaryLight};
  --positiveBgPrimary: #{$positiveBgPrimaryLight};
  --brandTextOnMuted: #{$brandTextOnMutedLight};
  --brandBgMuted: #{$brandBgMutedLight};
  --mainTextPrimary: #{$mainTextPrimaryLight};
  --mainBgPrimaryAlt: #{$mainBgPrimaryAltLight};
  --positiveBgMuted: #{$positiveBgMutedLight};
  --positiveTextOnMuted: #{$positiveTextOnMutedLight};
  --positiveTextPrimary: #{$positiveTextPrimaryLight};
  --negativeBgMuted: #{$negativeBgMutedLight};
  --negativeTextPrimary: #{$negativeTextPrimaryLight};
  --negativeTextOnMuted: #{$negativeTextOnMutedLight};
}

.indian_light {
  // indian_light inherits from .light
  @extend .light;
  --primaryTheme: #{$primaryThemeLightIndian};
  --labelBackground: #{$labelBackgroundLightIndian};
  --labelText: #{$labelTextLightIndian};
  --primaryButtonHover: #{$primaryButtonHoverLightIndian};
  --primaryButtonDisable: #{$primaryButtonDisableLightIndian};
  --secondaryCTA: #{$secondaryCTALightIndian};
  --secondaryCTAHover: #{$secondaryCTAHoverLightIndian};
  --signupButtonMobile: #{$signupButtonMobileLightIndian};
  --contractDetailMargin: #{$contractDetailMarginIndian};
  --contractDetailPadding: #{$contractDetailPaddingIndian};
  --selectionHighlightBackground: #{$selectionHighlightBackgroundLightIndia};
  --marketBannerBtnBgColor: #{$marketBannerBtnBgColorLightIndian};
  --marketBannerBgColor: #{$marketBannerBgColorLightIndian};
  --brandTextOnMuted: #{$brandTextOnMutedLightIndian};
  --brandBgMuted: #{$brandBgMutedLightIndian};
}

.indian_dark {
  // indian_dark inherits from :root which is default dark theme
  --primaryTheme: #{$primaryThemeIndian};
  --labelBackground: #{$labelBackgroundIndian};
  --labelText: #{$labelTextIndian};
  --primaryButtonHover: #{$primaryButtonHoverIndian};
  --primaryButtonDisable: #{$primaryButtonDisableIndian};
  --secondaryCTA: #{$secondaryCTAIndian};
  --secondaryCTAHover: #{$secondaryCTAHoverIndian};
  --signupButtonMobile: #{$signupButtonMobileIndian};
  --contractDetailMargin: #{$contractDetailMarginIndian};
  --contractDetailPadding: #{$contractDetailPaddingIndian};
  --selectionHighlightBackground: #{$selectionHighlightBackgroundIndia};
  --marketBannerBtnBgColor: #{$marketBannerBtnBgColorIndian};
  --marketBannerBgColor: #{$marketBannerBgColorIndian};
  --brandTextOnMuted: #{$brandTextOnMutedDarkIndian};
  --brandBgMuted: #{$brandBgMutedDarkIndian};
}
